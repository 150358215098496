import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconLogo from './IconLogo';
import css from './Logo.module.css';
import { useConfiguration } from '../../context/configurationContext';

const Logo = props => {
  const { className, format, ...rest } = props;

  const config = useConfiguration();

  const mobileClasses = classNames(css.logoMobile, className);

  const { logoImageDesktop, logoImageMobile } = config.branding;

  if (typeof logoImageDesktop === 'string' || typeof logoImageMobile === 'string') {
    return (
      <img
        className={classNames(css.logoImage, format === 'desktop' ? className : mobileClasses)}
        src={format === 'desktop' ? logoImageDesktop : logoImageMobile}
        alt={config.marketplaceName}
        {...rest}
      />
    );
  }

  // If you want to use image instead of svg as a logo you can use the following code.
  // Also, remember to import the image as LogoImage here.
  // <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />

  return (
    <IconLogo
      className={format === 'desktop' ? className : mobileClasses}
      format={format}
      {...rest}
    />
  );
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
